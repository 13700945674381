import React, { useEffect, useState, useMemo } from 'react';
import { Link, Redirect, useParams, useLocation } from "react-router-dom";
import Loader from "../../components/loader";

import './index.css';
import { doAttributePoint, getGrid, getUser, credit } from "../../api";
import { ReactComponent as ArrowBackIcon } from "../../images/icons/arrow_back_black_24dp.svg";
import { ReactComponent as StarIcon } from "../../images/icons/star_black_24dp.svg";
import { ReactComponent as StarOutlineIcon } from "../../images/icons/star_outline_black_24dp.svg";
import { padLeft } from "../../helpers/pad.js";
import { ReactComponent as CancelIcon } from "../../images/icons/cancel_black_24dp.svg";
import { ReactComponent as PriceCheckIcon } from "../../images/icons/price_check_black_24dp.svg";

const DATE_LABELS = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

function Grid() {
  const [user, setUser] = useState(null);
  const [grid, setGrid] = useState(null);
  const [editing, setEditing] = useState(null);
  const [editPoints, setEditPoints] = useState(0);
  const params = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [redeeming, setRedeeming] = useState(false);
  const [redeemingResult, setRedeemingResult] = useState(null);

  useEffect(() => {
    if(params.id) {
      Promise.all([
        getUser().then(user => () => setUser(user)),
        getGrid(params.id, params.user, searchParams.get('date')).then(grid => () => setGrid(grid)),
      ]).then(cbs => cbs.forEach(cb => cb()));
    }
  }, [params.id, params.user, searchParams]);

  if(!params.id) {
    return <Redirect to="/" />;
  }

  if(!grid) {
    return (
      <div className="Grid">
        <Loader />
      </div>
    );
  }

  // Columns for non date-based grids
  let columns = [{ id: 0, value: 0, label: "Points" }];
  if(grid.type > 0) {
    // Date based grid
    columns = [];
    for(let i = 0; i < grid.type; i++) {
      columns.push({ id: i, value: grid.start + (i * 86400), label: DATE_LABELS[i % DATE_LABELS.length] });
    }
  }

  const total = grid.lines.reduce((res, line) => (line.points || []).reduce((r, pts) => r + parseInt(pts[0], 10), res), 0);
  const date = !!grid.start && new Date(grid.start * 1000);
  const maxStars = grid.lines.reduce((m, l) => l.maxPoints > m ? l.maxPoints : m, 0) * columns.length;
  const starSize = maxStars > 10 ? "16px" : "20px";

  const userCanEdit = !!(user.rights && (user.rights & 0b0010));
  const gridIsEditable = userCanEdit && !grid.redeemed;
  const onClick = (lineId, value) => {
    const editLine = grid.lines.find(l => l.id === lineId);
    if(editLine) {
      const editPoints = ((editLine.points || []).find(v => v[1] === value) || [])[0] || 0;
      setEditing({ lineId, editLine, value, editPoints });
      setEditPoints(Math.min(editLine.maxPoints, Math.max(editPoints, 1)));
    }
  };
  const editLine = editing && editing.editLine;
  const editStars = [];
  if(editLine) {
    for(let i = 0; i < editLine.maxPoints; i++) {
      if(i < editPoints) {
        editStars.push(<StarIcon key={i} onClick={(e) => { e.stopPropagation(); setEditPoints(i + 1); }} fill="#FFDF00" width="32px" height="32px" />);
      } else {
        editStars.push(<StarOutlineIcon key={i} onClick={(e) => { e.stopPropagation(); setEditPoints(i + 1); }} fill="#CCCCCC" width="32px" height="32px" />);
      }
    }
  }
  const closeEditing = () => {
    if(editing && editPoints !== editing.editPoints) {
      doAttributePoint(editing.lineId, editing.value, editPoints, params.user).then((resp) => {
        if(resp.success) {
          setGrid({ ...grid, lines: grid.lines.map(l => {
              if(l.id !== editing.lineId) {
                return l;
              }

              const points = l.points || [];
              if(points.find(p => p[1] === editing.value)) {
                return { ...l, points: points.map(p => p[1] === editing.value ? [editPoints, editing.value] : p)};
              }

              return { ...l, points: points.concat([[editPoints, editing.value]])};
            })
          });
          setEditing(null);
        }
      });
    } else {
      setEditing(null);
    }
  }

  const doRedeem = (e) => {
    e.stopPropagation();

    const dateText = `${padLeft(date.getDate(), 2)}/${padLeft(date.getMonth() + 1, 2)}/${padLeft(date.getFullYear(), 4)}`;
    const message = grid.redeem_to_message || (date ? 'Semaine du {date}.' : "Points liés à \"{name}\"")

    credit(grid.redeem_to, total * 100, message.replace(/\{date\}/g, dateText).replace(/\{name\}/g, grid.name), grid.id + (grid.start ? '-' + grid.start : '')).then(result => {
      setRedeeming(false);
      if(result.success) {
        setGrid({ ...grid, redeemed: true });
        setRedeemingResult(<span>{total} <StarIcon fill="#000000" width="16px" height="16px" style={{ position: 'relative', top: 2 }} /> correctement crédité{total > 1 ? "s" : ""} au compte.</span>);
      } else {
        setRedeemingResult("Erreur lors de l'opération. Veuillez réessayer plus tard.");
      }
    }).catch(_ => {
      setRedeeming(false);
      setRedeemingResult("Erreur lors de l'opération. Veuillez réessayer plus tard.");
    });
  }

  // TODO : Navigate in past grids
  return (
    <div className="Grid">
      <div className="Grid-header">
        <div className="Grid-header-title">
          <div className="Grid-header-title-icon">
            <Link to={params.user && userCanEdit ? "/admin/attribute-points" : "/"}>
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
          </div>
          <div className="Grid-header-title-text">{grid.name} ({grid.description || ""})</div>
          {grid.redeem_to && userCanEdit && (
            <div className={"Account-header-title-action" + (grid.redeemed || total < 1 ? " Account-header-action-disabled" : "")} onClick={grid.redeemed ? undefined : () => setRedeeming(true)}>
              <PriceCheckIcon fill="#FFFFFF" width="32px" height="32px" />
            </div>
          )}
        </div>
        <div className="Grid-header-balance">
          {total} <StarIcon fill="#FFFFFF" width="32px" height="32px" />
        </div>
        {date && (
          <div className="Grid-header-description">
            <div className="Grid-header-description-arrow">
              {grid.prev && (
                <Link to={"/grid/" + params.id + (params.user ? "/" + params.user : "") + "?date=" + grid.prev}><ArrowBackIcon fill="#FFFFFF" width="24px" height="24px" /></Link>
              )}
            </div>
            <div className="Grid-header-description-text">Semaine du {padLeft(date.getDate(), 2)}/{padLeft(date.getMonth() + 1, 2)}/{padLeft(date.getFullYear(), 4)}.</div>
            <div className="Grid-header-description-arrow">
              {grid.next && (
                  <Link to={"/grid/" + params.id + (params.user ? "/" + params.user : "") + "?date=" + grid.next}><ArrowBackIcon style={{ transform: 'rotate(180deg)' }} fill="#FFFFFF" width="24px" height="24px" /></Link>
              )}
            </div>
          </div>
        )}
        {/*<div className="Grid-header-actions">*/}
        {/*  <Link to={{ pathname: "/transfer", state: { fromGrid: account.id }}} className="Grid-header-action-link">*/}
        {/*    <div className="Grid-header-action">*/}
        {/*      <div className="Grid-header-action-icon"><SendIcon fill="#FFFFFF" width="32px" height="32px" /></div>*/}
        {/*      <div className="Grid-header-action-text">Virement</div>*/}
        {/*    </div>*/}
        {/*  </Link>*/}
        {/*  <div className="Grid-header-action Grid-header-action-disabled">*/}
        {/*    <div className="Grid-header-action-icon"><TrendingUpIcon fill="#FFFFFF" width="32px" height="32px" /></div>*/}
        {/*    <div className="Grid-header-action-text">Graphique</div>*/}
        {/*  </div>*/}
        {/*  <div className="Grid-header-action Grid-header-action-disabled">*/}
        {/*    <div className="Grid-header-action-icon"><FileDownloadIcon fill="#FFFFFF" width="32px" height="32px" /></div>*/}
        {/*    <div className="Grid-header-action-text">Export</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className="Grid-content">
        {!grid.lines.length && (
          <div>Aucune action définie.</div>
        )}
        <div className="Grid-line Grid-line-header">
          <div className="Grid-line-cell Grid-line-name">Action</div>
          {columns.map(c => <div key={c.id} className="Grid-line-cell Grid-line-value">{c.label}</div>)}
        </div>
        {grid.lines.map(line => {
          return (
            <div key={line.id} className="Grid-line">
              <div className="Grid-line-cell Grid-line-name">
                {line.name}
              </div>
              {columns.map(c => {
                const stars = [];
                const points = ((line.points || []).find(p => p[1] === c.value) || [])[0] || 0;
                for(let i = 0; i < line.maxPoints; i++) {
                  if(i < points) {
                    stars.push(<StarIcon key={i} fill="#FFDF00" width={starSize} height={starSize} />);
                  } else {
                    stars.push(<StarOutlineIcon key={i} fill="#CCCCCC" width={starSize} height={starSize} />);
                  }
                }

                return (
                  <div key={c.id} className="Grid-line-cell Grid-line-value" onClick={gridIsEditable ? () => onClick(line.id, c.value) : undefined}>
                    {stars}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {editLine && (
          <div className="Grid-edit" onClick={closeEditing}>
            <div className="Grid-edit-content" onClick={(e) => { e.stopPropagation(); setEditPoints(0); }}>
              {editStars}
            </div>
            <div className="Grid-edit-cancel-buttons">
              <div style={{ flex: 1 }} />
              <CancelIcon fill="#FFFFFF" width="32px" height="32px" />
            </div>
          </div>
      )}
      {redeeming && (
          <div className="Grid-edit" onClick={() => setRedeeming(false)}>
            <div className="Grid-edit-content">
              <div>Créditer {total} <StarIcon fill="#000000" width="16px" height="16px" style={{ position: 'relative', top: 2 }} /> dans le compte associé ?</div>
              <div className="Grid-edit-actions">
                <div className="Transfer-button" onClick={doRedeem}>Créditer {total} <StarIcon fill="#FFFFFF" width="16px" height="16px" style={{ position: 'relative', top: 2 }} /></div>
              </div>
            </div>
            <div className="Grid-edit-cancel-buttons">
              <div style={{ flex: 1 }} />
              <CancelIcon fill="#FFFFFF" width="32px" height="32px" />
            </div>
          </div>
      )}
      {redeemingResult && (
          <div className="Grid-edit" onClick={() => setRedeemingResult(null)}>
            <div className="Grid-edit-content">
              <div>{redeemingResult}</div>
              <div className="Grid-edit-actions">
                <div className="Transfer-button">Valider</div>
              </div>
            </div>
            <div className="Grid-edit-cancel-buttons">
              <div style={{ flex: 1 }} />
              <CancelIcon fill="#FFFFFF" width="32px" height="32px" />
            </div>
          </div>
      )}
    </div>
  );
}

export default Grid;